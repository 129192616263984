import React, { Fragment } from "react";
import { useTranslate } from "react-polyglot";
import { useHistory } from "react-router-dom";

const TABLE_HEADER = [
  {
    key: "contract",
    title: "",
  },

  {
    key: "freemium",
    title: "general.freemium",
  },
  {
    key: "professional",
    title: "general.professional",
  },
];

const TABLE_DATA = [
  {
    id: 1,
    contract: "Funktionsumfang",
    freemium: " ",
    professional: " ",
    main: true,
  },
  {
    id: 2,
    contract: "Dashboard",
    freemium: "✓",
    professional: "✓",
    main: true,
  },
  {
    id: 3,
    contract: "General TOP search",
    freemium: "(Detaildaten ausgeblendet)",
    professional: "✓",
    main: true,
  },
  {
    id: 4,
    contract: "Unternehmen",
    freemium: "",
    professional: "",
    main: true,
  },
  {
    id: 5,
    contract: "Überblick",
    freemium: "(Detaildaten ausgeblendet)",
    professional: "✓",
  },
  {
    id: 6,
    contract: "Ähnliche Unternehmen",
    freemium: "(Detaildaten ausgeblendet)",
    professional: "✓",
  },
  {
    id: 7,
    contract: "Notizen",
    freemium: "✓",
    professional: "✓",
  },
  {
    id: 8,
    contract: "Gespeicherte Suchprofile",
    freemium: "",
    professional: "",
    main: true,
  },
  {
    id: 9,
    contract: "Suchprofil Matches",
    freemium: "(Detaildaten ausgeblendet)",
    professional: "✓",
  },
  {
    id: 10,
    contract: "Gespeicherte Suchen",
    freemium: "(Detaildaten ausgeblendet)",
    professional: "✓",
  },
  {
    id: 11,
    contract: "Favoriten",
    freemium: "(Detaildaten ausgeblendet)",
    professional: "✓",
  },
  {
    id: 12,
    contract: "Deal Pipeline",
    freemium: "(Detaildaten ausgeblendet)",
    professional: "✓",
    main: true,
  },
  {
    id: 13,
    contract: "News & Reports",
    freemium: "",
    professional: "",
    main: true,
  },
  {
    id: 14,
    contract: "M&A-Deal Tracker",
    freemium: "✓",
    professional: "✓",
  },
  {
    id: 15,
    contract: "M&A-Market Reports",
    freemium: "-",
    professional: "✓",
  },
  {
    id: 16,
    contract: "Vergleichstools",
    freemium: "",
    professional: "",
    main: true,
  },
  {
    id: 17,
    contract: "Unternehmen vergleichen",
    freemium: "(Detaildaten ausgeblendet)",
    professional: "✓",
  },
  {
    id: 18,
    contract: "Unternehmen analysieren",
    freemium: "(Detaildaten ausgeblendet)",
    professional: "✓",
  },
  {
    id: 19,
    contract: "Portfolio Management",
    freemium: "",
    professional: "",
    main: true,
  },
  {
    id: 20,
    contract: "Bestehende Plattformen",
    freemium: "eigene Plattformen, keine Wettbewerber",
    professional: "✓",
  },
  {
    id: 21,
    contract: "Portfolio-Ansichten",
    freemium: "✓",
    professional: "✓",
  },
  {
    id: 22,
    contract: "Tech Monitors",
    freemium: "-",
    professional: "✓",
    main: true,
  },
  {
    id: 23,
    contract: "Vergütung",
    freemium: "Für immer kostenlos.",
    professional:
      "Pro Benutzer und Monat mit Laufzeiten von12 oder 24 Monaten auf Jahresrechnung- Preis auf Anfrage -",
    main: true,
  },
];
const PricingTableDe = () => {
  const history = useHistory();
  const t = useTranslate();

  return (
    <Fragment>
      <span onClick={history.goBack} className="m-generalTerms__goBack"></span>
      <div className="m-generalTerms__table  -pricing">
        <span>Details zu unseren Paketen</span>
        <p>
          Vergleichen Sie unsere Pakete im Detail. So finden Sie ganz bestimmt
          das Paket, das genau zu Ihnen passt.
        </p>
        <table>
          <thead>
            <tr>
              {TABLE_HEADER.map(({ title }) => (
                <th className={`${title ? "" : "-blank"}`}>{t(title)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_DATA.map(({ contract, freemium, professional, main }) => (
              <tr className={`${main ? "-main" : ""}`}>
                <td>{contract}</td>
                <td>{freemium}</td>
                <td>{professional}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default PricingTableDe;
