import { ROLES } from 'constants/enums'
import ROUTES from 'constants/routes'

const MENU_ITEMS = [
    {
        key: 'dashboard',
        label: 'menuItems.dashboard',
        icon: 'dashboard',
        to: ROUTES.HOME,
    },
    {
        key: 'companies',
        label: 'menuItems.companies',
        icon: 'companies',
        to: ROUTES.COMPANIES,
    },
    {
        key: 'saved_profiles',
        label: 'menuItems.savedProfiles',
        icon: 'profiles',
        to: ROUTES.SAVED_PROFILES,
    },
    {
        key: 'deal_process',
        label: 'menuItems.dealProcess',
        icon: 'route',
        to: ROUTES.DEAL_PROCESS,
    },
    {
        key: 'invitations',
        label: 'menuItems.invitations',
        icon: 'mail',
        to: ROUTES.INVITATIONS,
    },
    {
        key: 'news',
        label: 'menuItems.news',
        icon: 'news',
        to: ROUTES.UPDATES
    },
    {
        key: 'comparison_tools',
        label: 'menuItems.comparisonTools',
        icon: 'heatmap',
        to: ROUTES.COMPARISON_TOOLS
    },
    {
        key: 'portfolio_management',
        label: 'menuItems.portfolioManagement',
        icon: 'portfolio',
        to: ROUTES.PORTFOLIO_MANAGEMENT
    },
    {
        key: 'tech_monitors',
        label: 'menuItems.techMonitors',
        icon: 'report',
        to: ROUTES.TECH_MONITORS,
    },
    // {
    //     key: 'investors',
    //     label: 'menuItems.investors',
    //     icon: 'users',
    //     to: '/investors',
    // },
    // {
    //     key: 'heatmap',
    //     icon: 'chart-radar',
    //     label: 'menuItems.heatmap',
    //     to: '/heatmap',
    // },
    // {
    //     key: 'comparison_tool',
    //     icon: 'compare',
    //     label: 'menuItems.comparisonTool',
    //     to: '/comparison-tool',
    // },
    // {
    //     key: 'portfolio',
    //     icon: 'briefcase',
    //     label: 'menuItems.portfolio',
    //     to: '/portfolio',
    // },
    // {
    //     key: 'settings',
    //     icon: 'settings',
    //     label: 'menuItems.settings',
    //     to: '/settings',
    // },
    // {
    //     key: 'profile',
    //     icon: 'user',
    //     label: 'menuItems.profile',
    //     to: ROUTES.USER_PROFILE,
    // }
]

const ADMIN_MENU_ITEMS = [
    ...MENU_ITEMS,
    {
        key: 'separator1',
        separator: true
    },
    {
        key: 'private-equities-management',
        label: 'menuItems.investor',
        icon: 'private-equity',
        to: ROUTES.PRIVATE_EQUITY_MANAGEMENT
    },
    {
        key: 'strategic-investor-management',
        label: 'menuItems.strategicInvestorManagement',
        icon: 'strategic-investor',
        to: ROUTES.STRATEGIC_INVESTOR_MANAGEMENT
    },
    {
        key: 'platforms-management',
        label: 'menuItems.platformsManagement',
        icon: 'platform',
        to: ROUTES.PLATFORM_MANAGEMENT
    },
    {
        key: 'companies-management',
        label: 'menuItems.companiesManagement',
        icon: 'versions',
        to: ROUTES.COMPANY_MANAGEMENT,
    },
    {
        key: 'groups-management',
        label: 'menuItems.groupsManagement',
        icon: 'group',
        to: ROUTES.GROUP_MANAGEMENT,
    },
    {
        key: 'saved-searches-management',
        label: 'menuItems.savedSearchesManagement',
        icon: 'profiles',
        to: ROUTES.SAVED_SEARCHES_MANAGEMENT,
    },
    {
        key: 'users-management',
        icon: 'users',
        label: 'menuItems.usersManagement',
        to: ROUTES.USER_MANAGEMENT,
    },
    {
        key: 'organisations-management',
        icon: 'organisation',
        label: 'menuItems.organisationManagement',
        to: ROUTES.ORGANISATION_MANAGEMENT,
    },
    {
        key: 'job_role_management',
        label: 'menuItems.jobRoleManagement',
        icon: 'job-role',
        to: ROUTES.JOB_ROLE_MANAGEMENT,
    },
    {
        key: 'tags-management',
        label: 'menuItems.tagsManagement',
        icon: 'tag',
        to: ROUTES.TAG_MANAGEMENT,
    },
    {
        key: 'technology-stacks-management',
        label: 'menuItems.technologyStacksManagement',
        icon: 'apps',
        to: ROUTES.TECHNOLOGY_STACK_MANAGEMENT,
    },
    {
        key: 'filters-management',
        label: 'menuItems.filtersManagement',
        icon: 'filter',
        to: ROUTES.FILTERS
    },
    {
        key: 'federation_managment',
        label: 'menuItems.federationManagement',
        icon: 'federation',
        to: ROUTES.FEDERATION_MANAGEMENT,
    },
    {
        key: 'countries_managment',
        label: 'menuItems.countriesManagment',
        icon: 'country',
        to: ROUTES.COUNTRIES_MENAGEMENT,
    },
    {
        key: 'clickworker-profile',
        label: 'menuItems.clickworkerProfile',
        icon: 'clickworker-profile',
        to: ROUTES.CLICKWORKER_PROFILE,
    },
    {
        key: 'separator2',
        separator: true
    },
    {
        key: 'news-management',
        label: 'menuItems.newsManagement',
        icon: 'notes',
        to: ROUTES.NEWS_MANAGEMENT,
    },
    {
        key: 'news-categories-management',
        label: 'menuItems.newsCategoriesManagement',
        icon: 'news-categories',
        to: ROUTES.NEWS_CATEGORY_MANAGEMENT,
    },
    {
        key: 'm&a-management',
        label: 'menuItems.m&aManagement',
        icon: 'm&a',
        to: ROUTES.ACQUISITION_NEWS_MANAGEMENT,
    },
    {
        key: 'm&a_deal_tracker',
        label: 'menuItems.dealTrackerManagement',
        icon: 'm&a_deal_tracker',
        to: ROUTES.DEAL_TRACKER_MANAGEMENT
    },
    {
        key: 'separator3',
        separator: true
    },
    {
        key: 'vendors-management',
        label: 'menuItems.vendorsManagement',
        icon: 'vendors',
        to: ROUTES.VENDOR_MANAGEMENT
    },    
    // TODO: Solution text is to big for menu - Link title and text-overflow: emphasis?
    {
        key: 'solution-partner-designation-management',
        label: 'menuItems.solutionPartnerDesignationManagement',
        icon: 'solutionPartnerDesignation',
        to: ROUTES.SOLUTION_PARTNER_DESIGNATION_MANAGEMENT
    },
    {
        key: 'advanced-specializations-management',
        label: 'menuItems.advancedSpecializationsManagement',
        icon: 'advancedSpecializations',
        to: ROUTES.ADVANCED_SPECIALIZATIONS_MANAGEMENT
    },
    // {
    //     key: 'vendor-categories-management',
    //     label: 'menuItems.vendorCategoriesManagement',
    //     icon: 'vendor-categories',
    //     to: ROUTES.VENDOR_CATEGORY_MANAGEMENT
    // },
    {
        key: 'partner-types-management',
        label: 'menuItems.partnerTypesManagement',
        icon: 'partner-type',
        to: ROUTES.PARTNER_TYPE_MANAGEMENT
    },
    {
        key: 'partner-levels-management',
        label: 'menuItems.partnerLevelsManagement',
        icon: 'partner-level',
        to: ROUTES.PARTNER_LEVEL_MANAGEMENT
    },
    {
        key: 'separator4',
        separator: true
    },
    {
        key: 'activities-management',
        label: 'menuItems.activitiesManagement',
        icon: 'activity',
        to: ROUTES.ACTIVITY_MANAGEMENT
    },
    {
        key: 'industries-management',
        label: 'menuItems.industriesManagement',
        icon: 'industry',
        to: ROUTES.INDUSTRY_MANAGEMENT
    },
    {
        key: 'software-categories-management',
        label: 'menuItems.softwareCategoriesManagement',
        icon: 'software-category',
        to: ROUTES.SOFTWARE_CATEGORY_MANAGEMENT
    },
    {
        key: 'portfolio-themes-management',
        label: 'menuItems.portfolioThemesManagement',
        icon: 'portfolio-themes',
        to: ROUTES.PORTFOLIO_THEMES_MANAGEMENT
    },
    {
        key: 'separator5',
        separator: true
    },
    {
        key: 'tech-monitors-management',
        label: 'menuItems.techMonitorsManagement',
        icon: 'chartSettings',
        to: ROUTES.TECH_MONITORS_MANAGEMENT
    },
    // {
    //     key: 'technology-management',
    //     label: 'menuItems.technologiesManagement',
    //     icon: 'technology',
    //     to: ROUTES.TECHNOLOGY_MANAGEMENT
    // },
    // {
    //     key: 'translations',
    //     label: 'menuItems.translations',
    //     icon: 'language',
    //     to: ROUTES.TRANSLATIONS
    // },
    // {
    //     key: 'settings',
    //     label: 'menuItems.settings',
    //     icon: 'settings',
    //     to: '/settings',
    // }
]

const CLICKWORKER_MENU_ITEMS = [
    {
        key: 'clickworker-profile',
        label: 'menuItems.clickworkerProfile',
        icon: 'clickworker-profile',
        to: ROUTES.CLICKWORKER_PROFILE,
    },
]

//TODO Add user menu items and just add admin part

const useMenuItems = userRoles => {
    if(!userRoles) return []

    const isAdmin = userRoles.some(role => role.name === ROLES.ADMIN.NAME)
    const isClickworker = userRoles.some(role => role.name === ROLES.CLICK_WORKER.NAME)

    switch (true) {
        case isAdmin:
            return ADMIN_MENU_ITEMS
        case isClickworker:
            return CLICKWORKER_MENU_ITEMS
        default:
            return MENU_ITEMS
    }
}

export default useMenuItems