import React, { useContext } from "react";
import { useTranslate } from "react-polyglot";
import { Link } from "react-router-dom";
import ROUTES from "constants/routes";
import ENTITY from "constants/entities";

import { TranslationContext } from "../../../context/TranslationContext";

import useFetchData from "hooks/useFetchData";

import GermanFlag from "../../../assets/images/germany-flag-icon-32.png";
import EnglishFlag from "../../../assets/images/united-kingdom-flag-icon-32.png";

const Footer = () => {
  const t = useTranslate();

  const {
    translations: { locale },
    setTranslations,
  } = useContext(TranslationContext);
  const {
    response: { data },
    isLoading,
  } = useFetchData(ENTITY.LANGUAGE, { pagination: false });

  if (isLoading) return null;

  return (
    <div className="m-noAuth__footer">
      <div className="footerContent">
        <div className="footerContent__links">
          <span>
            <Link to={ROUTES.GENERAL_TERMS}>{t("welcome.generalTerms")}</Link>
          </span>
          <span>
            <Link to={ROUTES.DATA_PROTECTION}>
              {t("welcome.dataProtection")}
            </Link>
          </span>
          <span>
            <Link to={ROUTES.IMPRINT_PAGE}>{t("welcome.imprint")}</Link>
          </span>
        </div>
        <div className="footerContent__date">
          <Link
            to={ROUTES.HOME}
          >{`© ${new Date().getFullYear()} evolutionplan AG`}</Link>
        </div>
        <div className="footerContent__flags">
          {data.map((language) => (
            <span key={language.id}>
              <img
                key={language.id}
                className={`footerContent__flags-img -${
                  language.code === locale ? "active" : ""
                }`}
                src={language.code === "en" ? EnglishFlag : GermanFlag}
                alt="Language"
                onClick={() => setTranslations(language)}
              />
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
