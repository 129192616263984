import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import ROUTES from "constants/routes";
import { logo } from "constants/images";

const GeneralTermsDE = () => {
  return (
    <Fragment>
      <Link to="/">
        <img src={logo} alt="logo" />
      </Link>
      <h4>
        Nutzungsbedingungen für die Web-Applikation (Applikation) roadX der
        evolutionplan AG, Neustadtstrasse 8a in 6003 Luzern, Schweiz
      </h4>
      <div className="m-generalTerms__parts">
        <span> I Betreiber</span>
        <ul>
          <li className="li-generalTerms">
            {" "}
            evolutionplan AG (nachfolgend „evolutionplan“) ist der Betreiber
            einer Web-Applikation, die über folgende Domains erreicht werden
            kann:{" "}
            <a href="https://roadx.app/login" target="_blank">
              www.roadx.tech
            </a>
            ,{" "}
            <a href="https://www.roadx.app" target="_blank">
              www.roadx.app
            </a>
            . Die Applikation ist in deutscher und englischer Sprache angelegt.
          </li>
        </ul>
      </div>
      <div className="m-generalTerms__parts">
        <span> II Grundlagen der Nutzung</span>
        <ul>
          <li className="li-generalTerms">
            In der Applikation werden Marktdaten in Verbindung mit Prozessen und
            Tools zur Unterstützung von Sourcing Strategien
            (buy-side-Strategien) mit den Schwerpunkten Marktscreening und Deal
            Pipeline Management bereitgestellt.
          </li>
          <li className="li-generalTerms">
            Die Applikation richtet sich in Version 1 an
            Beteiligungsgesellschaften (Private Equities), Family Offices und
            Strategische Investoren. Anwender (User) können z. B. sein:
            Geschäftsführer und Vorstände von Organisationen; M&A Manager,
            Investment Manager, Analysten und Portfoliomanager, jeweils in ihrer
            Funktion als für eine Organisation handelnde Person. Gleiches gilt
            für Inhaber von Unternehmensanteilen und Berater der vorgenannten
            Gruppen. Wer die Applikation nutzt, wird nachfolgend als „Nutzer“
            bezeichnet.
          </li>
          <li className="li-generalTerms">
            Das Angebot von evolutionplan richtet sich ausschliesslich an
            Unternehmer. Unternehmer ist eine natürliche oder juristische Person
            oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines
            Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen
            beruflichen Tätigkeit handelt.
          </li>
          <li className="li-generalTerms">
            Das Angebot von evolutionplan richtet sich nicht an Verbraucher.
            Verbraucher sind solche Personen, die ein Rechtsgeschäft zu Zwecken
            abschliessen, die überwiegend weder ihrer gewerblichen noch ihrer
            selbständigen beruflichen Tätigkeit zugerechnet werden können.
          </li>
        </ul>
      </div>
      <div className="m-generalTerms__parts">
        <span> III Zustandekommen des Nutzungsvertrages / Beendigung</span>
        <ul>
          <li className="li-generalTerms">
            Diese Nutzungsbedingungen regeln die vertraglichen Beziehungen
            zwischen evolutionplan und dem Nutzer im Rahmen der Nutzung der
            Applikation roadX.
          </li>
          <li className="li-generalTerms">
            Allgemeine Geschäftsbedingungen des Nutzers werden auch dann nicht
            Vertragsbestandteil, wenn evolutionplan deren Einbeziehung nicht
            ausdrücklich widerspricht.
          </li>
          <li className="li-generalTerms">
            In dem der Nutzer alle Pflichtfelder des Registrierungsformulars
            ausfüllt und das Formular an evolutionplan absendet macht der Nutzer
            evolutionplan ein Angebot zum Abschluss eines Vertrages auf
            Grundlage dieser Nutzungsbedingungen. Ein Angebot des Nutzers kann
            auch mündlich, telefonisch oder schriftlich erfolgen, wenn
            evolutionplan dieses Angebotsform im Einzelfall akzeptiert.
          </li>
          <li className="li-generalTerms">
            Der Eingang des Angebots stellt noch keine Annahme dar. Der Vertrag
            kommt erst zustande, wenn der Nutzer seinen persönlichen Account
            (Nutzerkonto) für roadX von evolutionplan freigeschaltet bekommt.
          </li>
          <li className="li-generalTerms">
            Ein Anspruch auf Freischaltung eines Nutzerkonto besteht nicht.
            evolutionplan behält sich die Prüfung vor, ob sie einen
            Nutzeraccount freischaltet.
          </li>
          <li className="li-generalTerms">
            evolutionplan behält sich das Recht vor, Nutzerkonten bei schweren
            oder wiederholten Verstössen gegen diese Nutzungsbedingungen
            temporär oder dauerhaft zu sperren. Weitere gesetzliche und
            vertragliche Rechte und Ansprüche von evolutionplan bleiben
            unberührt.
          </li>
          <li className="li-generalTerms">
            Ein schwerer Verstoss liegt insbesondere vor, wenn konkrete
            Anhaltspunkte dahingehend vorliegen, dass ein Nutzer falsche Angaben
            gemacht hat oder die Applikation entgegen dieser Nutzungsbedingungen
            nutzt oder seiner Zahlungspflicht nicht nachkommt.
          </li>
        </ul>
      </div>
      <div className="m-generalTerms__parts">
        <span> IV Leistungsbeschreibung</span>
        <ul>
          <li className="li-generalTerms">
            Nutzer können in der Applikation ihre Suchprofile hinterlegen. Im
            Hintergrund gleicht die Datenbank der Applikation ab, ob es passende
            Treffer („Matches“) gibt.
          </li>
          <li className="li-generalTerms">
            Diese „Matches“ können vom Nutzer in Suchprofilen gespeichert und
            mit Kommentaren versehen werden. Sie können ausserdem bewertet und
            miteinander verglichen werden.
          </li>
          <li className="li-generalTerms">
            Zusätzlich werden dem Nutzer ausgewählte Tech-Monitore angeboten,
            über die globale Marktdaten zu bestimmten Technologien, Herstellern
            und Anwendungsfeldern angezeigt werden.
          </li>
        </ul>
      </div>
      <div className="m-generalTerms__parts">
        <span>V Vergütung</span>
        <ul>
          <li className="li-generalTerms">
            evolutionplan bietet für roadX zwei (2) Vergütungsmodelle an. Diese
            heissen „Freemium“ und „Professional“. Die Leistungsbilder der
            beiden Vergütungsmodelle sind hier in der Vergütungstabelle
            beschrieben:{" "}
            <a href="https://roadx.app/pricing" target="_blank">
              www.roadx.tech/pricing{" "}
            </a>
          </li>
          <li className="li-generalTerms">
            evolutionplan behält sich das Recht vor, die Vergütungsmodelle und
            die Preisgestaltung mit Wirkung für die Zukunft zu ändern. Soweit
            nicht abweichend in Textform zwischen evolutionplan und dem Nutzer
            vereinbart, ist die Vergütung fällig mit Nutzung der Applikation.
          </li>
        </ul>
      </div>
      <div className="m-generalTerms__parts">
        <span>
          VI Untersagtes Verhalten / Auskunftsanspruch / Schadensersatz
        </span>
        <ul>
          <li className="li-generalTerms">
            Der Nutzer hat sämtliche Angaben, die er auf der Applikation
            hinterlegt, wahrheitsgemäss einzupflegen. evolutionlpan behält sich
            vor, Daten des Nutzers, bei denen der Verdacht eines Verstosses
            gegen die Wahrheitspflicht besteht, zu löschen.
          </li>
          <li className="li-generalTerms">
            Dem Nutzer sind sämtliche Handlungen untersagt, die die
            Funktionsfähigkeit oder die Integrität der Applikation gefährden
            könnten. Insbesondere darf die Applikation nicht für Spamming,
            unberechtigte Manipulationen von Daten und / oder Cyberattacken
            genutzt werden.
          </li>
          <li className="li-generalTerms">
            Dem Nutzer ist es untersagt, Daten und / oder sonstige Inhalte
            einzustellen, die Rechtsvorschriften oder Rechte Dritter verletzen.
          </li>
          <li className="li-generalTerms">
            Dem Nutzer ist es untersagt, die auf der Applikation roadX von
            evolutionplan aufbereiten Informationen und Daten, die für Nutzer
            zugänglich sind oder sonst dem Nutzer von evolutionplan zur
            Verfügung gestellt wurden, an Dritte weiterzugeben. Dies gilt
            insbesondere für personen- und unternehmensspezifische Daten, die
            dem Nutzer über die roadX Applikation zur Verfügung gestellt werden.
            Dritte sind insbesondere auch mit dem Nutzer direkt oder indirekt
            verbundene Unternehmen. Dritte sind nicht Angestellte und vom
            Investor offiziell für das Marktscreening und Deal Pipeline
            Management beauftragte Berater.
          </li>
          <li className="li-generalTerms">
            Wenn der Nutzer Informationen oder Daten im Sinne vorstehender
            Ziffer an Dritte weitergibt, ist evolutionplan berechtigt, vom
            Nutzer eine Vertragsstrafe in Höhe von zehntausend (10.000,00) EUR
            zu verlangen. Sowohl evolutionplan als auch dem Nutzer bleibt der
            Nachweis unbenommen, dass ein höherer oder niedrigerer Schaden
            entstanden ist.
          </li>
          <li className="li-generalTerms">
            Der Nutzer stellt evolutionplan von allen Schäden, Ansprüchen
            Dritter, Auslagen und Kosten (einschliesslich marktüblicher, nicht
            auf die gesetzlichen Gebühren beschränkter Anwaltshonorare) frei,
            die evolutionplan wegen eines Verstosses des Nutzers gegen
            Vorschriften aus dieser Nutzungsvereinbarung entstehen. Weitere
            vertragliche und gesetzliche Rechte und Ansprüche von evolutionplan
            bleiben unberührt.
          </li>
        </ul>
      </div>
      <div className="m-generalTerms__parts">
        <span>VII Rechenzentrum / Datenschutz</span>
        <ul>
          <li className="li-generalTerms">
            evolutionplan betreibt seine Applikation roadX im Rechenzentrum der
            Nine Internet Solutions AG, Badenerstrasse 47, 8004 Zürich.
          </li>
          <li className="li-generalTerms">
            Zwischen dem Dienstleister und der evolutionplan AG wurden
            angemessene Vereinbarungen zur Datensicherheit und zum Datenschutz
            abgeschlossen.
          </li>
        </ul>
      </div>
      <div className="m-generalTerms__parts">
        <span>VIII Haftung</span>
        <ul>
          <li className="li-generalTerms">
            evolutionplan verpflichtet sich gegenüber dem Nutzer, alle ihm
            unmittelbar zur Kenntnis gelangten Informationen streng vertraulich
            zu behandeln und sie nicht an unberechtigte Personen auszuhändigen,
            weiterzuleiten oder auf sonstige Weise zugänglich zu machen.
          </li>
          <li className="li-generalTerms">
            Die auf der Applikation von evolutionplan dargestellten
            Unternehmens-, Profil- und Projektdaten werden zum Teil auch von den
            jeweiligen Nutzern zur Verfügung gestellt oder ergänzt.
          </li>
          <li className="li-generalTerms">
            evolutionplan haftet nicht für die Richtigkeit und Vollständigkeit
            der auf der Applikation dargestellten Unternehmens- und
            Projektdaten.
          </li>
          <li className="li-generalTerms">
            evolutionplan haftet nicht für die Richtigkeit der Angaben auf der
            Applikation. evolutionplan sichert aber zu, die auf der Applikation
            bereitgestellten Daten nach bestem Gewissen geprüft zu haben.
          </li>
          <li className="li-generalTerms">
            evolutionplan haftet unbeschränkt für Vorsatz und grobe
            Fahrlässigkeit, sowie bei Schäden aus der Verletzung des Lebens, des
            Körpers oder der Gesundheit, bei Verletzung einer – ausdrücklich als
            solche zu bezeichnenden – Garantie, sowie nach dem
            Produkthaftungsgesetz.
          </li>
          <li className="li-generalTerms">
            In Fällen leichter Fahrlässigkeit haftet evolutionplan nur bei
            Verletzung einer wesentlichen Vertragspflicht. Eine wesentliche
            Vertragspflicht ist eine Pflicht, deren Erfüllung die Durchführung
            des Vertrages erst ermöglicht und auf deren Erfüllung sich der
            Nutzer deswegen regelmässig verlassen darf.
          </li>
          <li className="li-generalTerms">
            Die Haftung von evolutionplan ist auf den im Zeitpunkt des
            Vertragsschlusses vertragstypischen und vorhersehbaren Schadens
            begrenzt.
          </li>
          <li className="li-generalTerms">
            Die Haftungsbeschränkungen gelten entsprechend zugunsten der
            gesetzlichen Vertreter, Mitarbeiter, Beauftragten, Erfüllungs- und
            Verrichtungsgehilfen von evolutionplan.
          </li>
          <li className="li-generalTerms">
            Der Nutzer erkennt an, dass evolutionplan eine permanente
            Verfügbarkeit der Applikation nicht gewährleisten kann. Es besteht
            somit kein Anspruch auf eine permanente Verfügbarkeit. evolutionplan
            ist bemüht, eine Verfügbarkeit von fünfundneunzig (95) % im
            Jahresmittel (24 x365) bereitzustellen.
          </li>
        </ul>
      </div>
      <div className="m-generalTerms__parts">
        <span>IX Sonstiges</span>
        <ul>
          <li className="li-generalTerms">
            evolutionplan bemüht sich eine möglichst reibungslose und
            durchgehende Verfügbarkeit der Applikation zu gewährleisten
          </li>
          <li className="li-generalTerms">
            Da evolutionplan nicht an Verbraucher leistet, ist evolutionplan
            nicht dazu verpflichtet, an einem Streitbeilegungsverfahren vor
            einer Verbraucherschlichtungsstelle teilzunehmen.
          </li>
          <li className="li-generalTerms">
            evolutionplan ist berechtigt, diese Nutzungsbedingungen zu ändern.
          </li>
          <li className="li-generalTerms">
            Nutzer werden einen (1) Monat vor beabsichtigter Änderung der
            Nutzungsbedingungen über die im Nutzerprofil hinterlegte
            E-Mail-Adresse über die Änderung informiert. Widerspricht der Nutzer
            nicht innerhalb von zehn (10) Tagen nach Zugang der Nachricht über
            die Änderung der Nutzungsbedingungen, so gelten die neuen
            Nutzungsbedingungen als vom Nutzer akzeptiert.
          </li>
          <li className="li-generalTerms">
            Dieser Änderungsmechanismus gilt nicht für Änderungen der
            Hauptleistungspflichten der Parteien.
          </li>
          <li className="li-generalTerms">
            Es gilt das Recht der Schweiz unter Ausschluss des UN-Kaufrechts.
          </li>
          <li className="li-generalTerms">
            Ausschliesslicher Gerichtsstand für sämtliche Streitigkeiten aus
            oder im Zusammenhang mit diesem Vertrag ist Luzern.
          </li>
          <li className="li-generalTerms">
            The contract language is German. The English language version is for
            information only.
          </li>
          <li className="li-generalTerms">
            Sollten Bestimmungen dieser Nutzungsbedingungen unwirksam sein, so
            bleiben die übrigen Regelungen wirksam. Die Parteien werden die
            unwirksame Bestimmung durch eine solche ersetzen, die dem Willen der
            Parteien wirtschaftlich am nächsten kommt. Dies gilt entsprechend
            bei Regelungslücken.
          </li>
        </ul>
      </div>
      {/* <div className="m-generalTerms__table">
            <span>roadX – Vergütungsmodell</span>
            <p>Die Webapplikation umfasst in Version 1.0 die Vergütungsmodelle „Freemium“ und „Professional“. </p>
            <table >
              <TableHeader
                headerItems={TABLE_HEADER}
              />
              <TableBody
                headerItems={TABLE_HEADER}
                data={TABLE_DATA}
              />
            </table>
        </div> */}
    </Fragment>
  );
};

export default GeneralTermsDE;
