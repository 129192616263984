import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { logo } from "constants/images";

const DataProtectionEN = () => {
  return (
    <Fragment>
      <Link to="/">
        <img src={logo} alt="logo" />
      </Link>
      <h4>
        Privacy Policy for the web application roadX supplied by evolutionplan
        AG, Neustadtstrasse 8a in 6003 Lucerne, Switzerland
      </h4>
      <p>
        The following Privacy Policy provides you with information about your
        personal data that we process in connection with the use of our roadX
        web application and the functions, modules and components that can be
        accessed via it.
      </p>
      <div className="m-generalTerms__parts">
        <h3>Privacy Policy</h3>
      </div>
      <div className="m-generalTerms__parts">
        <span> 1. Scope and subject of the Privacy Policy</span>
        <p>
          The subject of this Privacy Policy is to provide information regarding
          the personal data that we collect via our roadX web-application, and
          the purpose for which we process it. Where we link to other sites, we
          have no influence or control over the linked content and the data
          protection provisions there. We recommend that you review the Privacy
          Policy on the linked websites to determine whether and to what extent
          personal data is collected, processed, used or made available to third
          parties.
        </p>
        <p>
          In the interest of readability, we refer to the masculine gender only.
          All references to individual people apply equally to all genders,
          masculine/feminine/other.
        </p>
      </div>
      <div className="m-generalTerms__parts">
        <span> 2. Data controller's contact details</span>
        <p>evolutionplan AG</p>
        <p>Oliver Wegner, CEO</p>
        <p>Neustadtstrasse 8a</p>
        <p>CH-6003 Lucerne</p>
      </div>
      <div className="m-generalTerms__parts">
        <span>3. Data protection contact details</span>
        <p>datenschutz@evolutionplan.com</p>
      </div>
      <div className="m-generalTerms__parts">
        <span>4. Protection of your personal data</span>
        <p>
          In the area of processing your personal data, we follow the
          stipulations of the Swiss Federal Act on Data Protection (FADP) and
          those of Regulation (EU) 2016/679 (General Data Protection
          Regulation).
        </p>
      </div>
      <div className="m-generalTerms__parts">
        <span>5. Collection of personal data</span>
        <p>
          We collect the following personal data when you use our roadX web
          application:
        </p>
        <div className="m-generalTerms__parts--list">
          <span>(1) Access data</span>
          <div>
            <p>
              This is required for the roadX web application to retrieve
              up-to-date data (e.g., warnings) or transmit certain data stored
              on the device to the server system. The access data includes the
              following data:
            </p>
            <ul>
              <li>IP address;</li>
              <li>date and time of retrieval;</li>
              <li>amount of data;</li>
              <li>confirmation of whether the data exchange was successful.</li>
            </ul>
          </div>
        </div>
        <div className="m-generalTerms__parts--list">
          <span>(2) When using the roadX web application</span>
          <div>
            <p>
              When using the roadX web application, we collect the personal data
              described below. We do this to support you in your sourcing
              strategies with a focus on market screening and deal pipeline
              management:
            </p>
            <span>Mandatory data:</span>
            <ul>
              <li>name and surname;</li>
              <li>email;</li>
              <li>mobile number and phone number;</li>
              <li>company name</li>
              <li>company address (street, postal code, city);</li>
            </ul>
            <span>Optional data:</span>
            <ul>
              <li>department and job title;</li>
              <li>language;</li>
              <li>portrait photo;</li>
            </ul>
            <p>
              The legal basis for the processing of this personal data is Art. 6
              (1) (b) GDPR. Your personal data will be stored for the purposes
              described above for two months after you unsubscribe and then
              automatically deleted. There is no obligation to provide the
              mandatory data described above. However, if you do not provide us
              with this personal data, you will not be able to use our web
              application.
            </p>
          </div>
        </div>
        <div className="m-generalTerms__parts--list">
          <span>(3) roadX web application log</span>
          <div>
            <p>
              To improve the roadX web application, we track your behaviour
              pseudonymously. The legal basis for the processing of this
              personal data is Art. 6 (1) (a) GDPR. Your personal data will be
              stored for the purposes described above. You can withdraw your
              consent at any time. There is no obligation to provide the
              mandatory data described above. However, if you do not provide us
              with this personal data, you will not be able to use our web
              application.
            </p>
          </div>
        </div>
        <div className="m-generalTerms__parts--list">
          <span>(4) App reporting</span>
          <div>
            <p>
              For our product analysis, we use the in-app reporting from the
              service provider InnerTrends Ltd. InnerTrends does not receive any
              of your personal data on the basis of the service and creates an
              anonymised report for us. We use these reports to understand and
              analyse user behaviour in the roadX web application. The legal
              basis for this processing is Art. 6 (1) (f) GDPR. We believe that,
              based on our implemented measures, our interest in processing the
              data prevails.
            </p>
          </div>
        </div>
      </div>

      <div className="m-generalTerms__parts">
        <span>6. Service providers</span>
        <p>
          We do not share your data with third parties. However, in the area of
          our roadX web application, we work together with professional service
          providers. Among other things, this contractual relationship is based
          on the providers’ obligation to have taken special precautions to
          protect your data and to process all personal data only in accordance
          with our instructions. In addition, all service providers have signed
          a data processing agreement, if necessary. You will find the
          categories of personal data recipients below:
        </p>
        <span>Service</span>
        <p>Provider</p>
        <p>Software developer</p>
        <p>Software maintenance</p>
      </div>
      <div className="m-generalTerms__parts">
        <span>7. Transmission to third countries </span>
        <div className="m-generalTerms__parts--list">
          <span>(1) Server location</span>
          <div>
            <p>
              We process your personal data exclusively on servers in
              Switzerland. roadX operates its application in the data centre of
              Nine Internet Solutions AG, Badenerstrasse 47, 8004 Zurich. The
              service provider and evolutionplan AG have concluded appropriate
              agreements on data security and data protection between
              themselves. Switzerland has an "adequacy decision" from the
              European Commission under Article 45 GDPR.This determines whether
              a third country (i.e., a country that is not bound by the GDPR) or
              an international organisation provides an adequate level of
              protection for personal data.
            </p>
          </div>
        </div>
        <div className="m-generalTerms__parts--list">
          <span>(2) Support/maintenance</span>
          <div>
            <p>
              In the area of maintenance and support of our roadX web
              application, we use the service provider ewasoft AG,
              Rathausstrasse 14. 6340 Baar. In doing so, we cannot rule out that
              data will be transferred to third countries on the basis of the
              service provided by ewasoft. We have enabled this transfer with
              regard to data protection law using the June 2021 Standard
              Contractual Clauses, in order to ensure compliance with the
              requirements of Chapter 5 of the GDPR and meet the requirements of
              Article 6 of the FADP. We also conducted a transfer impact
              assessment (TIA) as required by data privacy laws.
            </p>
          </div>
        </div>
      </div>
      <div className="m-generalTerms__parts">
        <span>8. Plugins and embedded functions</span>
        <p>
          We incorporate into our web application functional and content
          elements that are obtained from the servers of their respective
          providers (hereinafter referred to as "third-party providers"). This
          may include, for example, graphics, videos or social media buttons as
          well as posts (hereinafter uniformly referred to as "content").The
          integration always requires that the third-party providers of this
          content process the user’s IP address since they could not send the
          content to their browser without the IP address. The IP address is
          therefore required in order to display this content or these
          functions. However, we endeavour to only use content from providers
          who only use the IP addresses to deliver the content. Third parties
          may also use pixel tags (invisible graphics, also known as web
          beacons) for statistical or marketing purposes. Pixel tags allow
          information, such as our website’s traffic, to be evaluated. In
          addition, the pseudonymous information may be stored in cookies on the
          user's device and may include, but is not limited to, technical
          information about the browser and the operating system, referring
          websites, time of visit and other information regarding the use of our
          website, and be linked to such information from other sources.
        </p>
        <div className="m-generalTerms__parts--flex">
          <div className="m-generalTerms__parts--part">
            <span>Provider</span>
            <p>Google Ireland Limited</p>
            <p>Gordon House, Barrow Street</p>
            <p>Dublin 4, Ireland</p>
          </div>
          <div className="m-generalTerms__parts--part">
            <span>Service</span>
            <p>Google Maps:</p>
            <p>
              We integrate maps of the service Google Maps from the provider
              Google. The data processed may include, in particular, IP
              addresses and location data of users, which are however not
              collected without their consent (usually by the settings of their
              mobile devices)
            </p>
          </div>
          <div className="m-generalTerms__parts--part">
            <span>Legal basis</span>
            <p>Art. 6 (1) (a) GDPR.</p>
          </div>
          <div className="m-generalTerms__parts--part">
            <span>Information</span>
            <p>https://www.google.de/maps</p>
            <p>https://policies.google.com/privacy</p>
          </div>
        </div>
      </div>
      <div className="m-generalTerms__parts">
        <span>9. Data security</span>
        <p>
          We employ up-to-date technical measures to ensure data security, in
          particular to protect your personal data from risks during data
          transfers and to prevent it from becoming known by third parties.
          These measures are continuously adapted and reviewed according to the
          current state of the art. To protect the confidentiality and integrity
          of the data you provide on our website, this data is transmitted via
          "https" and Transport Layer Security (TLS).
        </p>
      </div>
      <div className="m-generalTerms__parts">
        <span>10. Your rights</span>
        <p>
          You have at any time the right to request confirmation from us as to
          whether we process your personal data and the right to obtain
          information regarding such personal data. In addition, you have the
          right to rectification, erasure and restriction of data processing, as
          well as the right to object at any time to the processing of the
          personal data, or to at any time revoke the consent to data processing
          or request the transfer of data.All wishes with regard to information,
          requests for information, revocations or objections to the processing
          of data should be directed via email to datenschutz@evolutionplan.com.
          In cases of data protection violations, you also have the right to
          lodge a complaint with a supervisory authority.
        </p>
      </div>
      <div className="m-generalTerms__parts">
        <span>11. Version</span>
        <p>
          This is the current version of our Privacy Policy (valid as of
          25/05/2023). We reserve the right to adapt this Privacy Policy (in
          particular in the event of changes to the legal situation or changes
          to the services). For this reason, we recommended that you access this
          Privacy Policy at regular intervals.
        </p>
      </div>
    </Fragment>
  );
};

export default DataProtectionEN;
