export default {
    HOME: '/',
    LOGIN: '/login',
    LINKEDIN: '/linkedin',
    REGISTER: '/register',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    ACTIVATE_USER: '/activate-user',
    COMPANY_OWNERS: '/company-owners',
    DASHBOARD: '/dashboard',
    USER_MANAGEMENT: '/user-management',
    COMPANY_MANAGEMENT: '/company-management',
    TAG_MANAGEMENT: '/tags-management',
    TECHNOLOGY_STACK_MANAGEMENT: '/technology-stack-management',
    NEWS_CATEGORY_MANAGEMENT: '/news-category-management',
    NEWS_MANAGEMENT: '/news-management',
    ACQUISITION_NEWS_MANAGEMENT: '/acquisition-news-management',
    VENDOR_MANAGEMENT: '/vendor-management',    
    SOLUTION_PARTNER_DESIGNATION_MANAGEMENT: '/solution-partner-designation-management',
    ADVANCED_SPECIALIZATIONS_MANAGEMENT: '/advanced-specializations-management',
    COUNTRIES_MENAGEMENT: '/countries-management',
    DEAL_TRACKER_MANAGEMENT: '/deal_trackers_management',
    // VENDOR_CATEGORY_MANAGEMENT: '/vendor-category-management',
    PARTNER_TYPE_MANAGEMENT: '/partner-type-management',
    PARTNER_LEVEL_MANAGEMENT: '/partner-level-management',
    PRIVATE_EQUITY_MANAGEMENT: '/private-equity-management',
    PLATFORM_MANAGEMENT: '/platform-management',
    ACTIVITY_MANAGEMENT: '/activity-management',
    INDUSTRY_MANAGEMENT: '/industry-management',
    SOFTWARE_CATEGORY_MANAGEMENT: '/software-category-management',
    PORTFOLIO_THEMES_MANAGEMENT: '/portfolio-themes-management',
    // TECHNOLOGY_MANAGEMENT: '/technology-management',
    CREATE: '/create',
    EDIT: '/edit',
    USER_PROFILE: '/user-profile',
    COMPANIES: '/companies',
    COMPANY_PROFILE: '/company-profile',
    HEATMAP: '/heatmap',
    RECENT_NEWS: '/recent-news',
    SIMILAR_COMPANIES: '/similar-companies',
    DEAL_PROCESS: '/deal-process',
    DEAL_REPORTS:'/deal-reports',
    TRANSLATIONS: "/translations",
    SUCCESS_BAROMETER: '/success-barometer',
    ALERTS: '/alerts',
    INVITE_TEAMMATES: '/invite-teammates',
    FILTERS: '/filters',
    CATEGORIES: '/categories',
    FILTER_ITEMS: '/filter-items',
    SAVED_PROFILES: '/saved-profiles',
    SAVED_SEARCHES: '/saved-searches',
    SEARCH_PROFILE_MATCHES: '/search-profile-matches',
    SAVED_COMPANIES: '/saved-companies',
    NOTES: '/notes',
    NEWS: '/news',
    // TECHNOLOGY_TRENDS: '/technology-trends',
    // REPORTS: '/reports',
    DEAL_TRACKER: '/deal-tracker',
    UPDATES: '/updates',
    PREMIUM: '/premium',
    COMPARISON_TOOLS: '/comparison-tools',
    // COMPARE_TARGETS: '/compare-targets',
    COMPANY_ANALYSOR: '/company-analysor',
    OWN_PORTFOLIO: '/own-portfolio',
    TECH_MONITORS: '/tech-monitors',
    PORTFOLIO_MANAGEMENT: '/portfolio-management',
    PLATFORM_OVERVIEW: '/platform-overview',
    TECH_MONITORS_MANAGEMENT: '/tech-monitor-management',
    TEAM_MANAGEMENT: '/team-management',
    EMPLOYEES_MANAGEMENT: '/employees-management',
    FINANCIALS_MANAGEMENT: '/financials-management',
    SAVED_SEARCHES_MANAGEMENT: '/saved-searches-management',
    STRATEGIC_INVESTOR_MANAGEMENT: '/strategic-investors-management',
    FEDERATION_MANAGEMENT: '/federation-management',
    GROUP_MANAGEMENT: '/group-management',
    PAYPAL_SUCCESS: '/paypal-success',
    PAYPAL_ERROR: '/paypal-error',
    BUYERS_MANAGEMENT: '/buyers_management',
    JOB_ROLE_MANAGEMENT: '/job-role-management',
    CLICKWORKER_PROFILE: '/clickworker-profile',
    CLICKWORKER_SEARCHES: '/clickworker-searches',
    CLICKWORKER_COMPANIES: '/clickworker-companies',
    FORMER_NAMES_MANAGEMENT: '/former_names_management',
    IMPRINT_PAGE: '/imprint',
    GENERAL_TERMS:'/general-terms-conditions',
    PRICING_PAGE: '/pricing',
    DATA_PROTECTION: '/data-protection',
    INVITATIONS: '/invites',
    INVITATIONS_SENT: '/invites-sent',
    ORGANISATION_MANAGEMENT: '/organisation-management',
    COMPANY_VENDORS_MANAGEMENT: '/company-vendors-management',
    MICROSOFT_MANAGEMENT: '/microsoft-management'
}