import React, { Fragment, useContext } from "react";
import { useTranslate } from "react-polyglot";
import { useHistory } from "react-router-dom";

const TABLE_HEADER = [
  {
    key: "contract",
    title: "",
  },

  {
    key: "freemium",
    title: "general.freemium",
  },
  {
    key: "professional",
    title: "general.professional",
  },
];

const TABLE_DATA = [
  {
    id: 1,
    contract: "Features & Functions",
    freemium: " ",
    professional: " ",
    main: true,
  },
  {
    id: 2,
    contract: "Dashboard",
    freemium: "✓",
    professional: "✓",
    main: true,
  },
  {
    id: 3,
    contract: "General TOP search",
    freemium: "(detail data is blurred)",
    professional: "✓",
    main: true,
  },
  {
    id: 4,
    contract: "Companies",
    freemium: "",
    professional: "",
    main: true,
  },
  {
    id: 5,
    contract: "Overview",
    freemium: "(detail data is blurred)",
    professional: "✓",
  },
  {
    id: 6,
    contract: "Similar Companies",
    freemium: "(detail data is blurred)",
    professional: "✓",
  },
  {
    id: 7,
    contract: "Notes",
    freemium: "✓",
    professional: "✓",
  },
  {
    id: 8,
    contract: "Saved Profiles",
    freemium: "",
    professional: "",
    main: true,
  },
  {
    id: 9,
    contract: "Saved Searches",
    freemium: "(detail data is blurred)",
    professional: "✓",
  },
  {
    id: 10,
    contract: "Search Profile Matches",
    freemium: "(detail data is blurred)",
    professional: "✓",
  },
  {
    id: 11,
    contract: "Favourite Companies",
    freemium: "(detail data is blurred)",
    professional: "✓",
  },
  {
    id: 12,
    contract: "Deal Pipeline",
    freemium: "(detail data is blurred)",
    professional: "✓",
    main: true,
  },
  {
    id: 13,
    contract: "News & Reports",
    freemium: "",
    professional: "",
    main: true,
  },
  {
    id: 14,
    contract: "M&A-Deal Tracker",
    freemium: "✓",
    professional: "✓",
  },
  {
    id: 15,
    contract: "M&A-Market Reports",
    freemium: "-",
    professional: "✓",
  },
  {
    id: 16,
    contract: "Comparison Tools",
    freemium: "",
    professional: "",
    main: true,
  },
  {
    id: 17,
    contract: "Compare Targets",
    freemium: "(detail data is blurred)",
    professional: "✓",
  },
  {
    id: 18,
    contract: "Company Analysor",
    freemium: "(detail data is blurred)",
    professional: "✓",
  },
  {
    id: 19,
    contract: "Portfolio Management",
    freemium: "",
    professional: "",
    main: true,
  },
  {
    id: 20,
    contract: "Platform View",
    freemium: "own platforms, no competitors",
    professional: "✓",
  },
  {
    id: 21,
    contract: "Portfolio View",
    freemium: "✓",
    professional: "✓",
  },
  {
    id: 22,
    contract: "Tech Monitors",
    freemium: "-",
    professional: "✓",
    main: true,
  },
  {
    id: 23,
    contract: "Subcription fee",
    freemium: "Free forever",
    professional:
      "Per user per month with subscription for 12 or 24 monthsannual invoice- Price on request -",
    main: true,
  },
];

const PricingTableEN = () => {
  const history = useHistory();
  const t = useTranslate();

  return (
    <Fragment>
      <span onClick={history.goBack} className="m-generalTerms__goBack"></span>
      <div className="m-generalTerms__table -pricing">
        <span>Details to our packages</span>
        <p>
          Compare our packages in detail. This way you are sure to find the
          package that exactly suits you.
        </p>
        <table>
          <thead>
            <tr>
              {TABLE_HEADER.map(({ title }, key) => (
                <th className={`${title ? "" : "-blank"}`} key={key}>
                  {t(title)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_DATA.map(({ contract, freemium, professional, main }) => (
              <tr className={`${main ? "-main" : ""}`}>
                <td>{contract}</td>
                <td>{freemium}</td>
                <td>{professional}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default PricingTableEN;
