import axios from 'axios';
import { deleteUserId, deleteUserToken, getUserToken, clearStorage } from 'services/localStorage.service';

export const BASE_URL = process.env.REACT_APP_API_HOST

export const GET = 'get'
export const POST = 'post'
export const PUT = 'put'
export const PATCH = 'patch'
export const DELETE = 'delete'

// axios.interceptors.response.use(null, (error) => {
//     if (error?.response?.status === 401 || error?.response?.status === 409) {
//         clearStorage()
//     }
// })

const axiosClient = (action, path, data, isJSONAPi = true) => {
    const token = getUserToken()
    const options = {
        headers: {
            'Content-Type': isJSONAPi ? 'application/vnd.api+json' : 'application/json',
            'Access-Control-Allow-Origin': '*',
        }
    }
    if (token) {
        options.headers['Authorization'] = `Bearer ${token}`
    }

    if (action === DELETE && data) {
        options.data = data
    }

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {

        if (error?.response?.status === 401 || error?.response?.status === 409) {
            deleteUserId()
            deleteUserToken()
        }
        return Promise.reject(error);
    })

    if (action === GET || action === DELETE) {
        return axios[action](`${BASE_URL}/api${path}`, options)
    }

    return axios[action](`${BASE_URL}/api${path}`, data, options)
}

export default axiosClient