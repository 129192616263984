import React, { Fragment, useContext, useState } from "react";
import Icon from "../../../components/Icon";
import Questions from "./Questions";

import YoutubeThumbnail from "../../../assets/images/oliver.png";
import { useTranslate } from "react-polyglot";
import { TranslationContext } from "context/TranslationContext";

const FAQ = () => {
  const [open, setOpen] = useState(false);
  const t = useTranslate();
  const {
    translations: { locale },
  } = useContext(TranslationContext);

  const videoUrl = `https://vimeo.com/${
    locale === "de" ? "868057346" : "868055426 "
  }`;

  return (
    <Fragment>
      <div className={`m-faqModal ${open ? "-show" : ""}`}>
        <div className="m-faqModal__content">
          <div className="m-faqModal__video">
            <p className="m-faqModal__video--quotation">
              <span>"</span>
              {t("FAQ.quotationFAQ")}
              <span>"</span>
            </p>
            <div className="m-faqModal__video--image">
              <a
                href={videoUrl}
                className="m-faqModal__video--overlay"
                target="_blank"
                rel="noreferrer"
              ></a>
              <img src={YoutubeThumbnail} alt="" />
            </div>
            <span className="m-faqModal__video--signature">
              {t("FAQ.imageSignature")}
            </span>
            <p>{t("FAQ.ctaFAQ")}</p>
          </div>
          <div className="m-faqModal__questions">
            <Questions />
          </div>
        </div>
        <div
          className="m-faqModal__overlay"
          onClick={() => setOpen(false)}
        ></div>
      </div>
      <button className="m-faqModal__button" onClick={() => setOpen(!open)}>
        <Icon name="youtube" color="#fff" />
        {t("noAuth.helloFAQ")}
      </button>
    </Fragment>
  );
};

export default FAQ;
