import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { logo } from "constants/images";

const ImprintEN = () => {
  return (
    <Fragment>
      <Link to="/">
        <img src={logo} alt="logo" />
      </Link>
      <div className="m-imprint__parts">
        <span>evolutionplan AG</span>
        <p>Neustadtstrasse 8a</p>
        <p>CH-6003 Lucerne</p>
        <p>Schwitzerland</p>
      </div>
      <div className="m-imprint__parts">
        <span>Authorized representative:</span>
        <p>CEO: Oliver Wegner</p>
      </div>
      <div className="m-imprint__parts">
        <span>Company identification number (UID)</span>
        <p>CHE-284.218.132</p>
      </div>
      <div className="m-imprint__parts">
        <span>Commercial Register Office:</span>
        <p>Handelsregisteramt des Kantons Luzern</p>
        <p>Bundesplatz 14</p>
        <p>CH-6002 Lucerne</p>
      </div>
      <div className="m-imprint__parts">
        <span>VAT number:</span>
        <p>CHE-284.218.132 MWST</p>
      </div>
      <div className="m-imprint__parts">
        <span>Internet:</span>
        <p>
          <a href="https://www.roadx.app" target="_blank">
            www.roadx.tech
          </a>
          ,{" "}
          <a href="https://www.roadx.app" target="_blank">
            www.roadx.app
          </a>
        </p>
      </div>
      <div className="m-imprint__parts">
        <p>
          <span>E-Mail:</span> info@evolutionplan.com
        </p>
      </div>
    </Fragment>
  );
};

export default ImprintEN;
