import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";

import ROUTES from "constants/routes";
import { logo } from "constants/images";

const GeneralTermsEN = () => {
  return (
    <Fragment>
      <Link to="/">
        <img src={logo} alt="logo" />
      </Link>
      <h4>
        Terms of Use for the Web Application ("Application") roadX of
        evolutionplan AG, Neustadtstrasse 8a in 6003 Luzern, Switzerland
      </h4>
      <div className="m-generalTerms__parts">
        <span> I Operator</span>
        <ul>
          <li className="li-generalTerms">
            {" "}
            evolutionplan AG (hereinafter referred to as "evolutionplan") is the
            operator of a web application ("Application") that can be reached
            via the following domains:{" "}
            <a href="https://roadx.app/login" target="_blank">
              www.roadx.tech
            </a>
            ,{" "}
            <a href="https://www.roadx.app" target="_blank">
              www.roadx.app
            </a>
            . The Application is available in German and English.
          </li>
        </ul>
      </div>
      <div className="m-generalTerms__parts">
        <span> II Basis of Use</span>
        <ul>
          <li className="li-generalTerms">
            The Application provides market data in connection with processes
            and tools to support sourcing strategies (buy side strategies) with
            the focus on market screening and deal pipeline management.
          </li>
          <li className="li-generalTerms">
            Version 1 of the Application is directed towards private equities,
            family offices and strategic investors. Users may be managing
            directors and executive board members of organisations, M&A
            managers, investment managers, analysts and portfolio managers in
            the capacity in which they act on behalf of an organisation. The
            same applies to owners of company shares and consultants of the
            aforementioned groups. Those who use the Application are hereinafter
            referred to as "Users".
          </li>
          <li className="li-generalTerms">
            The offering of evolutionplan is intended exclusively for
            entrepreneurs. An entrepreneur is any natural or legal person or
            incorporated partnership that concludes a legal transaction in
            exercise of its self-employed or commercial activity.
          </li>
          <li className="li-generalTerms">
            The offering of evolutionplan is not intended for consumers.
            Consumers are persons who conclude a legal transaction for purposes
            that are largely unrelated to their self-employed or commercial
            activity.
          </li>
        </ul>
      </div>
      <div className="m-generalTerms__parts">
        <span> III Coming into Force/Termination of the Usage Agreement</span>
        <ul>
          <li className="li-generalTerms">
            These Terms of Use govern the contractual relationship between
            evolutionplan and the User with respect to the use of the
            Application.
          </li>
          <li className="li-generalTerms">
            General terms and conditions of the user shall not become part of
            the agreement, even if evolutionplan does not expressly object to
            their inclusion.
          </li>
          <li className="li-generalTerms">
            By completing all mandatory fields of the registration form and
            submitting the form to evolutionplan, the User offers evolutionplan
            the conclusion of an agreement on the basis of these Terms of Use.
            The User's offer may also be made orally, by telephone or in
            writing, in the event evolutionplan accepts this offer form in
            individual cases.
          </li>
          <li className="li-generalTerms">
            The receipt of the offer does not in itself constitute an
            acceptance. The agreement shall only come into existence once
            evolutionplan activates the User's personal account (User account).
          </li>
          <li className="li-generalTerms">
            Activation of a User account cannot be demanded. evolutionplan
            reserves the right to consider whether or not to activate a User
            account.
          </li>
          <li className="li-generalTerms">
            evolutionplan reserves the right to temporarily or permanently block
            User accounts in the event of serious or repeated breaches of these
            Terms of Use. Further statutory and contractual rights and claims of
            evolutionplan remain unaffected.
          </li>
          <li className="li-generalTerms">
            A serious breach will be deemed to exist especially if there are
            clear indications that a User has provided false information or is
            using the Application in violation of these Terms of Use or does not
            comply with their payment obligations.
          </li>
        </ul>
      </div>
      <div className="m-generalTerms__parts">
        <span> IV Description of Services</span>
        <ul>
          <li className="li-generalTerms">
            Users can store their search profiles in the Application. In the
            background, the database of the Application checks whether there are
            any matches.
          </li>
          <li className="li-generalTerms">
            The User can store these matches in search profiles and enter
            comments for them. Moreover, they can be rated and compared with
            each other.
          </li>
          <li className="li-generalTerms">
            The User is additionally offered selected tech monitors, by means of
            which global market data on certain technologies, vendors and
            application fields are displayed.
          </li>
        </ul>
      </div>
      <div className="m-generalTerms__parts">
        <span>V Fee</span>
        <ul>
          <li className="li-generalTerms">
            evolutionplan offers for roadX two (2) fee models. These models are
            called "Freemium" and "Professional". The performance profiles of
            the two fee models are described in the fee table here:{" "}
            <a href="https://roadx.app/pricing" target="_blank">
              www.roadx.tech/pricing{" "}
            </a>
          </li>
          <li className="li-generalTerms">
            evolutionplan reserves the right to amend the fee models and the
            pricing with effect for the future. Unless agreed otherwise in text
            form between evolutionplan and the User, the fee shall be due upon
            use of the Application.
          </li>
        </ul>
      </div>
      <div className="m-generalTerms__parts">
        <span>VI Prohibited Behaviour, Right to Information, Damages</span>
        <ul>
          <li className="li-generalTerms">
            The User shall truthfully enter all details that he stores in the
            Application. evolutionplan reserves the right to delete any User
            data suspected of breaching the truthfulness obligation.
          </li>
          <li className="li-generalTerms">
            The User shall refrain from any and all actions that could endanger
            the operability or integrity of the Application. In particular, the
            Application shall not be used for spamming, unauthorised data
            manipulation and/or cyber-attacks.
          </li>
          <li className="li-generalTerms">
            The User shall not upload any data and/or other content that breach
            legal regulations or third-party rights.
          </li>
          <li className="li-generalTerms">
            The User is prohibited from sharing the information and data
            processed in the Application roadX of evolutionplan, which are
            accessible to the User or which evolutionplan has otherwise made
            available to the user, with third parties. This includes, but is not
            limited, to personal and company-specific data that are made
            available to the User via the roadX Application. The term "third
            parties" expressly also includes companies directly or indirectly
            affiliated with the User. The term "third parties" does not include
            employees and consultants officially commissioned by the User for
            the market screening and deal pipeline management.
          </li>
          <li className="li-generalTerms">
            If the User shares information or data in the sense of the above
            subsection with third parties, evolutionplan may demand a penalty in
            the amount of ten thousand euros (€10,000) from the User. Both
            evolutionplan and the User are free to furnish evidence that the
            damage was higher or lower.
          </li>
          <li className="li-generalTerms">
            The User indemnifies evolutionplan against all damage, third-party
            claims, expenses and costs (including lawyers fees that are usual on
            the market; this is not limited to statutory fees), that
            evolutionplan incurs due to the User's breach of regulations from
            these Terms of Use. Further contractual and statutory rights and
            claims of evolutionplan remain unaffected.
          </li>
        </ul>
      </div>
      <div className="m-generalTerms__parts">
        <span>VII Data Centre, Data Protection</span>
        <ul>
          <li className="li-generalTerms">
            Evolutionplan AG operates its Application roadX in the data centre
            of Nine Internet Solutions AG, Badenerstrasse 47, 8004 Zürich,
            Switzerland.
          </li>
          <li className="li-generalTerms">
            Adequate data security and data protection agreements have been
            concluded between the service provider and evolutionplan AG.
          </li>
        </ul>
      </div>
      <div className="m-generalTerms__parts">
        <span>VIII Liability</span>
        <ul>
          <li className="li-generalTerms">
            evolutionplan undertakes with regard to the User to treat all
            information it directly learns of as strictly confidential and not
            to hand this over, forward it or otherwise make it accessible to
            unauthorised persons.
          </li>
          <li className="li-generalTerms">
            Some of the company, profile and project data presented in the
            Application of evolutionplan are also made available or supplemented
            by the respective Users.
          </li>
          <li className="li-generalTerms">
            evolutionplan shall not be liable for the correctness and
            completeness of the company and project data presented in the
            Application.
          </li>
          <li className="li-generalTerms">
            evolutionplan shall not be liable for the correctness of the
            information in the Application. However, evolutionplan confirms that
            it has checked the data made available in the Application to the
            best of its knowledge and belief.
          </li>
          <li className="li-generalTerms">
            evolutionplan shall be fully liable for intent and gross negligence
            in the event of damage from injury to life, body and health, in the
            event of a breach of a guarantee expressly designated as such or
            pursuant to the Swiss Product Liability Act (PLA).
          </li>
          <li className="li-generalTerms">
            In cases of slight negligence, evolutionplan shall only be liable in
            the event of a breach of a material contractual obligation. A
            material contractual obligation is an obligation whose fulfilment is
            essential to the performance of the agreement and on whose
            fulfilment the User may thus always rely.
          </li>
          <li className="li-generalTerms">
            The liability of evolutionplan is limited to the damage that, as of
            the conclusion of the agreement, is foreseeable and typical for the
            Agreement.
          </li>
          <li className="li-generalTerms">
            By analogy, the disclaimer also applies to the benefit of legal
            representatives, employees, appointees and agents of evolutionplan.
          </li>
          <li className="li-generalTerms">
            The User acknowledges that evolutionplan cannot guarantee permanent
            availability of the Application. Permanent availability can thus not
            be demanded. Nevertheless, evolutionplan endeavours to ensure an
            average availability of ninety-five percent (95%) a year (24 x 365).
          </li>
        </ul>
      </div>
      <div className="m-generalTerms__parts">
        <span>IX Miscellaneous</span>
        <ul>
          <li className="li-generalTerms">
            evolutionplan will do its best to ensure smooth, ongoing
            availability of the Application.
          </li>
          <li className="li-generalTerms">
            As evolutionplan does not service consumers, evolutionplan is under
            no obligation to participate in arbitration proceedings of a
            consumer arbitration board.
          </li>
          <li className="li-generalTerms">
            evolutionplan may amend these Terms of Use.
          </li>
          <li className="li-generalTerms">
            Users will be informed of any intended amendments to the Terms of
            Use one (1) month in advance via the e-mail address stored in the
            User profile. If the User does not raise any objections within ten
            (10) days of the receipt of the notification about the amendment to
            the Terms of Use, the new Terms of Use will be deemed accepted by
            the User.
          </li>
          <li className="li-generalTerms">
            This amendment mechanism shall not apply to amendments to the
            Parties' cardinal obligations.
          </li>
          <li className="li-generalTerms">
            The laws of Switzerland shall apply, under exclusion of the UN
            Convention on Contracts for the International Sale of Goods (CISG).
          </li>
          <li className="li-generalTerms">
            The courts of Lucerne, Switzerland, shall have exclusive
            jurisdiction over all disputes arising from or in connection with
            these Terms of Use.
          </li>
          <li className="li-generalTerms">
            The contract language is German. The English language version is for
            information only.
          </li>
          <li className="li-generalTerms">
            Should any provisions of these Terms of Use be invalid, the other
            regulations shall remain valid. The Parties shall replace the
            invalid provision with a provision whose economic effect comes as
            close as possible to what is intended by the Parties. By way of
            analogy, this shall also apply in the case of any missing
            regulations.
          </li>
        </ul>
      </div>
      {/* <div className="m-generalTerms__table">
            <span>roadX – Vergütungsmodell</span>
            <p>Die Webapplikation umfasst in Version 1.0 die Vergütungsmodelle „Freemium“ und „Professional“. </p>
            <table >
              <TableHeader
                headerItems={TABLE_HEADER}
              />
              <TableBody
                headerItems={TABLE_HEADER}
                data={TABLE_DATA}
              />
            </table>
        </div> */}
    </Fragment>
  );
};

export default GeneralTermsEN;
