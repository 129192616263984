import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { Link } from 'react-router-dom'
import ROUTES from 'constants/routes'

import Icon from 'components/Icon'
import { getUserAdvancedFilters } from 'services/localStorage.service'

const SideBarItem = ({ label, to, onClick, icon, active }) => {
    const t = useTranslate()
    const handleClick = () => {
        if (onClick && typeof onClick === 'function') {
            onClick()
        }
    }

    const getRoute = route => {
        if (route === ROUTES.COMPANIES) {
            const advancedFilters = getUserAdvancedFilters()
            return advancedFilters ? `${route}${advancedFilters}` : route
        }
        return route ? route : '#'
    }
    return (
        <Link
            className={`m-sidenav__item ${active ? ' -active' : ''}`}
            onClick={handleClick}
            to={getRoute(to)}
            title={t(label)}
        >
            <Icon name={icon} color='#B4B9C4' /> <span className='m-sidenav__itemLabel'>{t(label)}</span>
        </Link>
    )
}

SideBarItem.propTypes = {
    label: PropTypes.string.isRequired,
    to: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    active: PropTypes.bool
}

export default SideBarItem